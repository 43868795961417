import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import { navigate } from 'gatsby';
import 'antd/dist/antd.css';
import './index.css';
import { fbq } from 'gatsby-plugin-facebook-pixel';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import GoogleaAddressSearch from '../../GoogleAutoComplete';
import {
  createUsers,
  getUser,
  showLoader,
  hideLoader,
  updateUserInfo,
} from '../../../../actions';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { loadState } from '../../../../store';
import { calculateBounds } from 'tsparticles/Utils';
import { Helmet } from 'react-helmet';
import PdfTermsOfService from '../../../../documents/termsOfService.pdf';
import PdfElectronicCommunicationPolicy from '../../../../documents/electronicCommunicationsPolicy.pdf';
import PdfPrivacyPolicy from '../../../../documents/privacyPolicy.pdf';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const FifthPageHELOCIncomeComponent = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  title2,
  description,
  description2,
  createUsers,
  showLoader,
  hideLoader,
  loading,
  currentUser,
  user,
}) => {
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const onFinish = async (values) => {
    //call faecboook pixel upon clicking submit heloc
    var updateObject = {
      borrower: {
        firstName: JSON.parse(localStorage.getItem('firstName')),
        lastName: JSON.parse(localStorage.getItem('lastName')),
        suffix: JSON.parse(localStorage.getItem('suffix')),
        dateOfBirth: JSON.parse(localStorage.getItem('dateOfBirth')),
        addressLineText: JSON.parse(
          localStorage.getItem('primaryResidencePropertyAddress')
        ),
        cityName: JSON.parse(localStorage.getItem('primaryResidenceCityName')),
        stateCode: JSON.parse(
          localStorage.getItem('primaryResidenceStateCode')
        ),
        postalCode: JSON.parse(
          localStorage.getItem('primaryResidencePostalCode')
        ),
        countryCode: JSON.parse(localStorage.getItem('borrowerCountryCode')),
        contactPointTelephoneValue: values.phoneNumber,
        income: {
          totalYearlyIncome: JSON.parse(localStorage.getItem('annualIncome')),
          totalOtherIncome: JSON.parse(localStorage.getItem('otherIncome')),
        },
      },
      subjectProperty: {
        address: {
          addressLineText: JSON.parse(
            localStorage.getItem('subjectPropertyAddress')
          ),
          addressUnitIdentifier: JSON.parse(
            localStorage.getItem('addressUnitIdentifier')
          ),
          cityName: JSON.parse(localStorage.getItem('subjectPropertyCity')),
          stateCode: JSON.parse(localStorage.getItem('subjectPropertyState')),
          postalCode: JSON.parse(
            localStorage.getItem('subjectPropertyZipCode')
          ),
        },
        propertyDetail: {
          propertyUsageType: JSON.parse(
            localStorage.getItem('subjectPropertyUsageType')
          ),
        },
      },
      loan: {
        termsOfLoan: {
          loanPurposeType: 'Heloc',
        },
      },
      miscellaneous: {
        financingPurpose: JSON.parse(localStorage.getItem('financingPurpose')),
        //financingUse:
      },
      email: values.email,
    };

    if (typeof fbq !== 'undefined') {
      fbq('track', 'ApplicationSubmitted');
    }
    //localStorage.setItem('email', JSON.stringify(values.email));
    //localStorage.setItem('contactPointTelephoneValue', JSON.stringify(values.phoneNumber));
    showLoader();
    createUsers(updateObject);
    await sleep(3000);
    setFormSubmitted(true);
    hideLoader();
    //navigate('/heloc/sixthPageHELOCPassword/')
  };

  useEffect(() => {
    console.log('currentUser is changed', currentUser);
    console.log('form submitted is changed', formSubmitted);
    // hideLoader();
    // if( currentUser && currentUser.borrower)
    // {
    //   navigate(currentUser.borrower.helocLinkURL);
    // }
    if (currentUser && currentUser.borrower && formSubmitted) {
      hideLoader();
      setFormSubmitted(true);
      //navigate(currentUser.borrower.helocLinkURL);
      //setFormSubmitted(false);
    }
  }, [currentUser]); // <-- empty dependency array

  return (
    <SectionMainWrapper>
      {/* display this once form submitted */}
      {formSubmitted && (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}>
              <Box {...row}>
                <FeatureBlock title={<Heading {...title2} />} />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {/* display this briefly after form submission */}
      {loading ? (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}>
              <Box {...row}>
                <FullPageLoader />
              </Box>
            </Box>
          </Container>
        </Box>
      ) : formSubmitted ? (
        ''
      ) : (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}></Box>
            <Box {...row}>
              {/*  <Box {...contactForm}> */}

              <ContactFromWrapper>
                <Form
                  //{...formItemLayout}
                  form={form}
                  layout="vertical"
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                  style={{ width: '80%', fontWeight: '600' }}
                >
                  <FeatureBlock
                    title={<Heading {...title} />}
                    description={<Text {...description} />}
                  />

                  <Form.Item
                    name="phoneNumber"
                    label="Phone number"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                      {
                        min: 12,
                        message: '*Must be 10 digits',
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="888 888 8888"
                      options={{ phone: true, phoneRegionCode: 'US' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter a valid E-mail',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="text"
                    valuePropName="checked"
                    //onChange={handleAcceptedTerms}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject('Required'),
                      },
                    ]}
                  >
                    <Checkbox>
                      I agree to be contacted by Best Finance via call, email
                      and text. To opt-out, you can reply “stop” at any time or
                      click the unsubscribe link in the emails. Message and data
                      rates may apply.
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    //onChange={handleAcceptedTerms}
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject('Required'),
                      },
                    ]}
                  >
                    <Checkbox>
                      I agree to the
                      <a href={PdfTermsOfService} target="_blank">
                        {' '}
                        <u>Terms of Use</u>
                        {''}
                      </a>
                      ,
                      <a href={PdfPrivacyPolicy} target="_blank">
                        {' '}
                        <u>Privacy Policy</u>
                      </a>
                      , and
                      <a
                        href={PdfElectronicCommunicationPolicy}
                        target="_blank"
                      >
                        {' '}
                        <u>Consent to Receive Electronic Loan Documents</u>{' '}
                      </a>
                    </Checkbox>
                  </Form.Item>

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //color="#131176"
                      //onClick={blank()}
                      {...button}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </ContactFromWrapper>
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
      )}
    </SectionMainWrapper>
  );
};

FifthPageHELOCIncomeComponent.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

FifthPageHELOCIncomeComponent.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    //pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['20px', '20px', '20px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    fontColor: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
    marginRight: '8px',
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'We just need your best contact information.',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },
  title2: {
    content:
      'Thanks! Your info is being evaluated now.  We will contact you shortly about your equity!',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },

  description: {
    content: 'We need to send your approval somewhere.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
  },
};

const mapStateToProps = ({ root: { currentUser, loading, showSidebar } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
  loading,
  showSidebar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
    getUser: (userId) => dispatch(getUser(userId)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    //toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

const FifthPageHELOCIncomeComponentRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(FifthPageHELOCIncomeComponent);

export default FifthPageHELOCIncomeComponentRedux;
